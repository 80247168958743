import {Translation} from "../../infrastructure/dto/articles_graph_response";
import {ArticleDates, ArticleDatesProps} from "../model/article-dates-view-model";

export class ArticleDatesFactory {
    private response: Translation;

    constructor(response: Translation) {
        this.response = response;
    }

    public build(): ArticleDates {
        const props: ArticleDatesProps = {
            created: this.response.date_created,
            updated: this.response.date_updated
        }
        return ArticleDates.create(props);
    }


}