import {graphql, useStaticQuery} from "gatsby"
import {ArticleJsonLd, FAQJsonLd, GatsbySeo} from "gatsby-plugin-next-seo";
import {useI18next} from "gatsby-plugin-react-i18next";
import React from "react";
import metaPublisherJson from "../../../../../../shared/seo/presentation/seo-meta-publisher";
import {ArticleAggregateViewModel} from "../../model/article-aggregate-view-model";
import {ArticleViewModel} from "../../model/article-view-model";

const getLogoUrl = () => {
	const data = useStaticQuery(graphql`
		{
		  file(name: {eq: "sushi-pedia-logo"}) {
			publicURL
		  }
		}
	  `)
	return data.file.publicURL
}


export interface ArticleSeoProps {
	locale: string
	viewModel: ArticleViewModel
}

export default function ArticleSeo({locale, viewModel}: ArticleSeoProps) {

	const {t} = useI18next();
	const translation = viewModel.translation;
	const metaTags = metaPublisherJson()

	const languageAlternates = viewModel.props.available_alternates.map((translation) => {
		return {
			hrefLang: translation.locale,
			href: t("Site.url") + "/" + translation.locale + "/" + translation.slug,
		}
	})

	const JsonLdArticle = () => (
		<>
			<ArticleJsonLd
				url={t("Site.url") + "/" + translation.props.language.locale + "/" + translation.props.locator.slug}
				headline={translation.props.content.headline}
				images={[
					t("Site.url") + translation.props.images.raw_data.opener[0].node.publicURL
				]}
				datePublished={translation.props.date.localize_created_date()}
				dateModified={translation.props.date.localize_updated_date() ?? undefined}
				authorName='Sushipedia'
				publisherName='Sushipedia'
				publisherLogo={t("Site.url") + getLogoUrl()}
				description={translation.props.content.intro}
				// overrides={{
				// 	'@type': 'BlogPosting', // set's this as a blog post.
				// }}
			/>
		</>
	)

	const JsonFaq = () => {
		if (translation.props.faq) {
			return (
				<FAQJsonLd
					questions={
						translation.props.faq.map(faq_item => {
							return {
								question: faq_item.props.question,
								answer: faq_item.props.answer
							}
						})
					}
				/>
			)
		} else {
			return (<></>)
		}
	}


	return (
		<>
			<GatsbySeo
				language={locale}
				title={translation.props.meta.title}
				description={translation.props.meta.description}
				languageAlternates={languageAlternates}
				metaTags={metaTags}
			/>
			<JsonLdArticle/>
			<JsonFaq/>
		</>
	)
}

