import {CalendarIcon, RefreshIcon, UserIcon} from "@heroicons/react/outline";
import React from "react";
import {ArticleTranslationViewModel} from "../../model/article-translation-view-model";

export interface ArticleAuthorDatesProps {
    articleTranslationViewModel: ArticleTranslationViewModel
}

export default function ArticleAuthorDates({articleTranslationViewModel}: ArticleAuthorDatesProps) {

    function DateInformation() {
        const created = articleTranslationViewModel.props.date.localize_created_date()
        const updated = articleTranslationViewModel.props.date.localize_updated_date()

        return (
            <>
                {/* Created */}
                <div className="flex flex-row gap-1 items-center">
                    <CalendarIcon className={"h-4"}/>
                    {/* Created Date */}
                    <span className="text-sm">{created} </span>
                </div>

                {/* Updated */}
                {updated &&
                    <div className="flex flex-row gap-1 items-center">
                        <RefreshIcon className={"h-4"}/>
                        <span className="text-sm">{updated} </span>
                    </div>}
            </>
        )
    }

    function AuthorInformation() {
        const authors = articleTranslationViewModel.props.authors;
        if (authors?.length ?? 0 > 0) {
            return (
                <>
                    <div className="flex flex-row gap-1 items-center ">
                        <UserIcon className={"h-4"}/>
                        {authors.map((author, index) =>
                            <span className="text-sm" key={index}>
                                {author.name}{(index + 1) !== (authors?.length) ? ", ": ""}
                            </span>
                        )}
                    </div>
                </>
            )
        } else {
            return (<></>)
        }
    }

    return (
        <>
            <div className="flex flex-col sm:flex-row sm:gap-4 bg-white border-b border-t text-base mb-4 py-1">
                <AuthorInformation/>
                <DateInformation/>
            </div>
        </>
    )
}