import React from "react";
import parse from "html-react-parser";
import ArticleHeadingH2 from "../content/article-content-heading-h2";
import SourceViewModelNew from "../../../../../../shared/source/application/model/source-view-model-new";
import {SourceCite} from "../../../../../../shared/source/presentation/component/source-cite";
import {useI18next} from "gatsby-plugin-react-i18next";

export interface ArticleSourcesProps {
	sources: SourceViewModelNew[]
}

export default function ArticleSources({sources}: ArticleSourcesProps) {

	const {t} = useI18next();
	const sources_sorted = sources.sort((a, b) => (a.props.short < b.props.short) ? -1:1)

	return (
		<>
			<ArticleHeadingH2 id="sources">
				{t("Article.Sources.title")}
			</ArticleHeadingH2>
			<ol className="list-disc">
				{sources_sorted.map((source, index) => {
					return (
						<li className="break-words my-2"
							id={source.props.short}
							key={index}>
							<SourceCite addShort={true} viewModel={source}/>
						</li>
					)
				})}
			</ol>
		</>
	)
}
