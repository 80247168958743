import {Translation} from "../../infrastructure/dto/articles_graph_response";
import {ArticleContentType} from "../model/article-translation-view-model";
import ArticleContentSectionViewModel, {ArticleContentSectionViewModelProps} from "../model/article-content-view-model";

export class ArticleContentFactory {
    private response: Translation;

    constructor(response: Translation) {
        this.response = response;
    }

    public build(): ArticleContentType {
        return {
            enable_toc: this.map_enable_toc(),
            headline: this.map_headline(),
            intro: this.map_intro(),
            keywords: this.map_keywords(),
            sections: this.map_sections(),
            subline: this.map_subline()
        }
    }

    private map_enable_toc(): boolean {
        return this.response.create_table_of_contents;
    }

    private map_headline(): string {
        return this.response.headline;
    }

    private map_intro(): string {
        return this.response.intro
    }

    private map_keywords(): string[] {
        return this.response.keywords;
    }

    private map_sections(): ArticleContentSectionViewModel[] {
        const sections = this.response.article_sections;
        if (sections) {
            return sections.map((section) => {
                const props: ArticleContentSectionViewModelProps = {
                    content: section.content,
                    heading: section.heading
                }
                return ArticleContentSectionViewModel.create(props);
            })
        } else {
            return []
        }
    }

    private map_subline(): string {
        return this.response.subline;
    }
}