import {Article, ArticleAggregateGraphQlResponse} from "../../infrastructure/dto/articles_graph_response";
import {
    ArticleAlternate,
    ArticleLocators,
    ArticleViewModel,
    ArticleViewModelProps
} from "../../presentation/model/article-view-model";
import SourceViewModelNew from "../../../../../shared/source/application/model/source-view-model-new";
import {SourceViewModelFactory} from "../../../../../shared/source/application/mapper/from-graphql-to-view-model";
import {ArticleTranslationViewModel} from "../../presentation/model/article-translation-view-model";
import {ArticleTranslationFactory} from "../../presentation/factory/article-translation-factory";


export class MapArticleGraphResponseToViewModel {
    private readonly response: ArticleAggregateGraphQlResponse;

    // Filtered by uuid and language in graphql
    private readonly articleResponse: Article;

    constructor(response: ArticleAggregateGraphQlResponse) {
        this.response = response;

        // Filtered by uuid and language in graphql
        this.articleResponse = response.directus.articles[0];
    }

    public execute(): ArticleViewModel {
        const props: ArticleViewModelProps = {
            available_alternates: this.map_available_alternates(),
            locators: this.map_slugs(),
            sources: this.map_sources(),
            translation: this.map_article_translation()
        }
        return ArticleViewModel.create(props);
    }

    private map_slugs(): ArticleLocators {
        // Filtered by uuid and language in graphql
        const article_slug = this.response.directus.articles[0].translations[0].slug;
        const umbrella_slug = this.response.directus.articles[0].umbrella_slug;
        return {article_slug: article_slug, umbrella_slug: umbrella_slug}
    }

    private map_available_alternates(): ArticleAlternate[] {
        // Filtered by uuid in graphql
        return this.response.available_languages.articles[0].translations.map((translation) => {
            return {
                locale: translation.languages_code.locale,
                slug: translation.slug
            }
        })
    }

    private map_sources(): SourceViewModelNew[] {
        return this.articleResponse.sources.map((source) => {
            return new SourceViewModelFactory(source.sources_id).execute();
        });
    }

    private map_article_translation(): ArticleTranslationViewModel[] {
        return this.articleResponse.translations.map((translation) => {
            const opener_image = this.response.images_opener.edges;
            const content_images = this.response.images_content.edges;
            // Constructor order must be correct
            return new ArticleTranslationFactory(translation, content_images, opener_image).build()
        })
    }

}