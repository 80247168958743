import React from "react";


const ArticleHeadingH2 = (props: {
	id: string | null,
	children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined;
}) => {
	return (
		<>
			<h2 id={props.id ?? ""} className="mt-10 text-xl lg:text-2xl font-bold mb-3 bg-gray-50 rounded-r-lg p-3 border-l-8">
				{props.children}
			</h2>
		</>
	)
}

export default ArticleHeadingH2;
