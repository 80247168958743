import {Translation} from "../../infrastructure/dto/articles_graph_response";
import FaqViewModel, {FaqViewModelProps} from "../../../../../shared/faq/application/model/faq-view-model";

export class ArticleFaqFactory {
    private readonly response: Translation;

    constructor(response: Translation) {
        this.response = response;
    }

    public build(): FaqViewModel[] {
        const faqs = this.response.faq;
        if (faqs) {
            return faqs.map((faq) => {
                const props: FaqViewModelProps = {
                    answer: faq.answer,
                    question: faq.question
                }
                return FaqViewModel.create(props)
            })
        } else {
            return []
        }
    }
}