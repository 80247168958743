export default function metaPublisherJson() {
	return [
		{
			name: `author`,
			content: 'Sushipedia',
		},
		{
			name: `publisher`,
			content: 'Arconia UG',
		},
	]
}
