import {useI18next} from "gatsby-plugin-react-i18next";
import React from "react";
// @ts-ignore
import Faq from 'react-faq-component';
import FaqViewModel from "../../../../../../shared/faq/application/model/faq-view-model";
import ArticleHeadingH2 from "../content/article-content-heading-h2";
import {ArticleFaqContentParser} from "./article-faq-content-parser";

export interface ArticleFaqProps {
	items: FaqViewModel[]
}

export default function ArticleFaq({items}: ArticleFaqProps) {

	const {t} = useI18next();

	const data = {
		title: null,
		rows: items.map((faq_item) => {
			return {
				title: faq_item.props.question,
				content: <div className="mb-6 bg-gray-50 rounded-lg p-5">
					<ArticleFaqContentParser htmlInput={faq_item.props.answer}/>
				</div>
			}
		})
	}

	// see: https://www.npmjs.com/package/react-faq-component
	const config = {
		// animate: true,
		// arrowIcon: "V",
		// tabFocus: true
	};

	// see: https://www.npmjs.com/package/react-faq-component
	const styles = {
		// bgColor: 'white',
		// rowContentColor: 'grey',
		// arrowColor: "red",
	};

	return (
		<div className="">
			<a href="#toc">
				<ArticleHeadingH2 id={"faq"}>
					{t("Article.Faq.title")}
				</ArticleHeadingH2>
			</a>
			<div>
				<Faq
					data={data}
					config={config}
					styles={styles}
				/>
			</div>
		</div>
	)
}
