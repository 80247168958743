import parse, {domToReact, Element, HTMLReactParserOptions} from "html-react-parser";
import * as React from "react";
import ArticleHeadingH3 from "../content/article-content-heading-h3";
import ArticleParagraph from "../content/article-content-paragraph";

export interface ArticleFaqContentParserProps {
	htmlInput: string,
}

export function ArticleFaqContentParser({htmlInput}: ArticleFaqContentParserProps) {

	const options: HTMLReactParserOptions = {
		trim: true,
		// @ts-ignore
		replace: (domNode) => {

			if (!domNode) {
				return;
			}
			const dom: any = domNode

			// if (dom.attribs && dom.name === "blockquote") {
			// 	return replaceWithQuoteComponent(domNode)
			// }

			// if (dom.attribs && dom.name === "div" && dom.attribs.class === 'quote') {
			// 	return replaceWithQuoteComponent(domNode)
			// }

			if (dom.attribs && dom.name === "a" && dom.attribs.class === 'ref') {
				return replaceReferenceWithLink(domNode)
			}

			if (dom.attribs && dom.name === "a" && dom.attribs.class === 'outbound') {
				return replaceExternalLink(domNode, options)
			}

		}
	}

	const parsed = parse(htmlInput, options)

	// Return as Element
	return parsed as JSX.Element
}

function replaceReferenceWithLink(domNode: any) {
	const src = domNode.attribs['short']
	return (
		<a className="ref" href={`#${src}`} title={src}>[{src}]</a>
	)
}

function replaceExternalLink(domNode: any, options: any) {
	const href = domNode.attribs['href']
	const title = domNode.attribs['title']
	const rel = "external nofollow noreferrer noopener";
	const policy = "no-referrer"
	return (
		<a className="outbound underline decoration-solid"
		   target="_blank"
		   title={title ?? href}
		   rel={rel}
		   referrerPolicy={policy}
		   href={href}>
			{domToReact(domNode.children, options)}
		</a>
	)
}
