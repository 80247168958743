import {graphql} from "gatsby";
import {useI18next} from "gatsby-plugin-react-i18next";
import React from "react";
// @ts-ignore
import Layout from "../components/layout"
import ArticleSeo from "../modules/article/submodule/article/presentation/component/seo/article-seo";
import ArticlePageContent from "../modules/article/submodule/article/presentation/page/article-page-content";
import {
	MapArticleGraphResponseToViewModel
} from "../modules/article/submodule/article/application/usecase/map-article-graph-response-to-view-model";

/*
{
  "language": "de",
  "article_aggregate_id": "0d5af067-91aa-448d-ac3a-863d763c04b2",
  "regex_images_opener": "/ingredients/sushi-sashimi-grade-guide/opener/",
  "regex_images_content": "/ingredients/sushi-sashimi-grade-guide/content/"
}
 */

export const query = graphql`
fragment LanguageDataArticle on DIRECTUS_languages {
  code
  locale
  name
}

fragment SourceData on DIRECTUS_sources {
  id
  author
  author_translated_roman
  doi
  journal_issue
  journal_name
  journal_volume
  pages
  publication_date
  publication_year
  published_language {
    ...LanguageData
  }
  publisher
  short
  sort
  status
  title
  title_translated_english
  type
  url
  date_retrieved
}

query ($article_aggregate_id: String!, $language: String!, $regex_images_opener: String!, $regex_images_content: String!) {
  directus: directus {
    articles(filter: {id: {_eq: $article_aggregate_id}}) {
      id
      umbrella_slug
      internal_title
      status
      category
      date_created
      date_updated
      sort
      translations(filter: {languages_code: {locale: {_eq: $language}}}) {
        slug
        languages_code {
          ...LanguageDataArticle
        }
        id
        authors
        headline
        intro
        keywords
        create_table_of_contents
        article
        article_sections
        faq
        date_created
        date_updated
        meta_description
        meta_title
        status
        subline
      }
      sources {
        sources_id {
          ...SourceData
        }
      }
    }
  }
  available_languages: directus {
    articles(filter: {id: {_eq: $article_aggregate_id}}) {
      id
      umbrella_slug
      status
      translations {
        slug
        status
        languages_code {
          ...LanguageDataArticle
        }
      }
    }
  }
  images_opener: allFile(
    filter: {sourceInstanceName: {eq: "media"}, extension: {in: ["jpg", "png"]}, relativeDirectory: {regex: $regex_images_opener}}
  ) {
    edges {
      node {
        id
        sourceInstanceName
        name
        relativePath
        relativeDirectory
        publicURL
        ext
        extension
        dir
        root
        base
        absolutePath
        childImageSharp {
          id
          gatsbyImageData(
            width: 1200
            quality: 80
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  }
  images_content: allFile(
    filter: {sourceInstanceName: {eq: "media"}, extension: {in: ["jpg", "png"]}, relativeDirectory: {regex: $regex_images_content}}
  ) {
    edges {
      node {
        id
        sourceInstanceName
        name
        relativePath
        relativeDirectory
        publicURL
        ext
        extension
        dir
        root
        base
        absolutePath
        childImageSharp {
          id
          gatsbyImageData(
            width: 1200
            quality: 80
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  }
  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}
`

export default function ArticleTemplatePage({data}: any) {

	const {language} = useI18next();
	let composeArticleUseCase = new MapArticleGraphResponseToViewModel(data);
	let viewModel = composeArticleUseCase.execute();

	return (
		<>
			{/*<pre>data: {JSON.stringify(viewModel, null, 4)}</pre>*/}
			<ArticleSeo locale={language} viewModel={viewModel}/>
			<Layout>
				<ArticlePageContent articleAggregate={viewModel} />
			</Layout>
		</>
	)
}
