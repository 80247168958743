import FaqViewModel from "../../../../../shared/faq/application/model/faq-view-model";
import {ViewModel} from "../../../../../shared/building_blocks/view_model";
import ArticleContentSectionViewModel from "./article-content-view-model";
import {ArticleAuthor} from "../../../../../../../../../core/src/modules/article/domain/value_objects/article_author";
import DomainArticle from "../../../../../../../../../core/src/modules/article/domain/entity/domain-article";
import {ArticleDates} from "./article-dates-view-model";
import {GraphImagesEdge} from "../../../../../shared/infrastructure/graph-image-node";

export type ArticleAuthorsType = {
	name: string,
	email: string
}

export type ArticleContentType = {
	headline: string
	subline: string
	intro: string
	sections: ArticleContentSectionViewModel[]
	keywords: string[]
	enable_toc: boolean
}

export type ArticleImagesType = {
	// raw edge nodes
	raw_data: {
		opener: GraphImagesEdge[]
		content: GraphImagesEdge[]
	}
}

export interface ArticleTranslationViewModelProps {
	locator: {
		slug: string
	},
	language: {
		locale: string,
	}
	meta: {
		title: string
		description: string
	}
	content: ArticleContentType
	date: ArticleDates
	authors: ArticleAuthorsType[]
	// authors: string[] | null
	faq: FaqViewModel[]
	images: ArticleImagesType
}

export class ArticleTranslationViewModel extends ViewModel<ArticleTranslationViewModelProps> {

	private static domainArticle: DomainArticle;

	private constructor(props: ArticleTranslationViewModelProps) {
		super(props);
	}

	public static create(props: ArticleTranslationViewModelProps): ArticleTranslationViewModel {
		return new ArticleTranslationViewModel({...props})
	}

	private static decorate_authors(): string[] | null {
		const authors: ArticleAuthor[] = ArticleTranslationViewModel.domainArticle.props.authors

		if (authors) {
			if (authors.length > 0) {
				return authors.map((author) => {
					return `<a href="mailto:unavailable -- please use official contact">${author.props.name}</a>`
				})
			}
		}

		return null;
	}

}
