import {ViewModel} from "../../../../../shared/building_blocks/view_model";
import React from "react";
import {I18nextContext} from "gatsby-plugin-react-i18next";

export interface ArticleDatesProps {
    created: Date,
    updated: Date| null,
}

export class ArticleDates extends ViewModel<ArticleDatesProps> {
    private constructor(props: ArticleDatesProps) {
        super(props);
    }

    public static create(props: ArticleDatesProps): ArticleDates {
        return new ArticleDates({...props})
    }

    public localize_created_date(): string {
        const date = this.props.created;
        return ArticleDates.localize_date(date)
    }

    public localize_updated_date(): string | null {
        const date = this.props.updated;
        if (date) {
            return ArticleDates.localize_date(date)
        } else {
            return null;
        }
    }

    private static localize_date(date: Date): string {
        const context = React.useContext(I18nextContext);
        const locale = context.language;

        const options = {year: 'numeric', month: 'long', day: '2-digit'} as const;
        return new Date(date).toLocaleDateString(locale, options);
    }
}
