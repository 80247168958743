import {GatsbyImage, getImage} from "gatsby-plugin-image";
import {useTranslation} from "gatsby-plugin-react-i18next";
import React from "react";
import {useAppStore} from "../../../../../../state/app_state";
import {ArticleContentParser} from "../component/content/article-content-parser";
import ArticleSources from "../component/sources/article-sources";
import ArticleToc from "../component/content/article-toc";
import ArticleHeadingH2 from "../component/content/article-content-heading-h2";
import ArticleFaq from "../component/faq/article-faq";
import ArticleAuthorDates from "../component/authors/article-author-dates";
import {ArticleViewModel} from "../model/article-view-model";

export type ArticlePageContentProps = {
	articleAggregate: ArticleViewModel
}
export default function ArticlePageContent({articleAggregate}: ArticlePageContentProps) {

	// i18n
	const {t} = useTranslation();

	// State
	const setArticlesSection = useAppStore(state => state.setArticles);
	setArticlesSection();

	const translation = articleAggregate.translation;

	// Opener
	const imageEdge = translation.props.images.raw_data.opener[0].node;

	// Faq
	const hasFaq = translation.props.faq.length > 0;

	return (
		<>
			<div className="layout-containerized font-sans">
				<div className="flex flex-col py-3 px-3">

					{/*<pre className="bg-white">{JSON.stringify(localized_article, null, 4)}</pre>*/}

					{/* Category Bread Crumb*/}
					<div className="flex flex-row">
						{/* Article */}
						<span className="font-extrabold uppercase text-salmonRed text-sm pb-3">
							<a className="hover:underline hover:decoration-solid" href={`${t("Site.article_feed_uri")}`} >{t("Article.article")}</a>
						</span>
						<span className="font-base uppercase text-noriGreen text-sm px-2">{"|"}</span>
						<span className="font-extrabold uppercase text-salmonRed text-sm pb-3">Sushi KnowHow 寿司の知識</span>
					</div>


					{/* Author */}
					<ArticleAuthorDates articleTranslationViewModel={translation} />

					{/* Heading */}
					<h1 className="mb-4 text-4xl font-extrabold">{translation.props.content.headline}</h1>
					<h3 className="text-xl font-light text-salmonRed">{translation.props.content.subline}</h3>

					{/* Opener Image */}
					{imageEdge && <figure className="bg-slate-100 rounded mt-4">
						<div className="">
							{/* @ts-ignore image bug */}
							<GatsbyImage className="rounded" image={getImage(imageEdge)} alt={translation.props.content.headline}/>
						</div>
					</figure>}

					{/* Table of Contents */}
					<ArticleToc enable={translation.props.content.enable_toc}
								hasFaqs={hasFaq}
								intro={translation.props.content.intro}
								headings={translation.props.content.sections.map(sections => sections.props.heading)}/>

					{/* Content */}
					{translation.props.content.sections.map((sections, index) =>
						<div key={`${index}`}>
							<div className="mt-6 background-slate-100">
								<a href={`#toc`}>
									<ArticleHeadingH2 id={`heading-${sections.props.heading}`}>
										{sections.props.heading}
									</ArticleHeadingH2>
								</a>
							</div>
							<ArticleContentParser key={`content-${index}`} htmlInput={sections.props.content}
												  contentImageNodes={translation.props.images.raw_data.content}/>
						</div>
					)}

					{/* FAQ */}
					{translation.props.faq.length > 0 && <ArticleFaq items={translation.props.faq}/>}

					{/* Sources */}
					{articleAggregate.props.sources.length > 0 && <ArticleSources sources={articleAggregate.props.sources}/>}

				</div>
			</div>
		</>
	)
}
