import {useTranslation} from "gatsby-plugin-react-i18next";
import React from "react";
import ArticleHeadingH2 from "./article-content-heading-h2";

export interface ArticleTocProps {
    enable: boolean | null
    hasFaqs: boolean
    intro: string | null
    headings: string[]
}

export default function ArticleToc({enable, intro, headings, hasFaqs}: ArticleTocProps) {
    const {t} = useTranslation();

    if (enable) {
        return (
            <>
                {/* Heading of Toc */}
                <ArticleHeadingH2 id={'toc'}>
                    {t('Article.table_of_contents')}
                </ArticleHeadingH2>

                {/* Intro */}
                {intro && <p className="pb-3">{intro}</p>}

                {/* Headings */}
                <ul className="pl-5 list-decimal leading-loose font-bold">

                    {/* Sources */}
                    {headings.map((heading, index) =>
                        <li className="" key={`li-toc-${index}`} id={`li-toc-${index}`}>
                            <a href={`#heading-${heading}`}
                               className={"hover:text-salmonRed underline underline-offset-4 decoration-solid decoration-sky-500/30"}>
                                <span className="">{heading}</span>
                            </a>
                        </li>
                    )}

                    {hasFaqs &&
                        <li className="" key={`li-toc-faq`} id={`li-toc-faq`}>
                            <a href={`#faq`}
                               className={"hover:text-salmonRed underline underline-offset-4 decoration-solid decoration-sky-500/30"}>
                                <span className="">{t("Article.Faq.title")}</span>
                            </a>
                        </li>}

                </ul>
            </>
        )
    } else {
        return (<></>)
    }
}
