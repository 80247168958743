import {ViewModel} from "../../../../../shared/building_blocks/view_model";
import {
	ArticleContent
} from "../../../../../../../../../core/src/modules/article/domain/value_objects/article_content_section";

export interface ArticleContentSectionViewModelProps {
	heading: string
	content: string
}

export default class ArticleContentViewModel extends ViewModel<ArticleContentSectionViewModelProps> {

	private constructor(props: ArticleContentSectionViewModelProps) {
		super(props);
	}

	public static create(props: ArticleContentSectionViewModelProps): ArticleContentViewModel {
		return new ArticleContentViewModel({...props})
	}


	public static create_from_domain(domainFaq: ArticleContent): ArticleContentViewModel {
		const props: ArticleContentSectionViewModelProps = {
			heading: domainFaq.props.heading,
			content: domainFaq.props.content
		}
		return new ArticleContentViewModel(props);
	}
}
