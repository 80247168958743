import {Images, Translation} from "../../infrastructure/dto/articles_graph_response";
import {
    ArticleAuthorsType, ArticleContentType, ArticleImagesType,
    ArticleTranslationViewModel,
    ArticleTranslationViewModelProps
} from "../model/article-translation-view-model";
import {ArticleContentFactory} from "./article-content-factory";
import {ArticleDates} from "../model/article-dates-view-model";
import {ArticleDatesFactory} from "./article-dates-factory";
import {ArticleFaqFactory} from "./article-faq-factory";
import FaqViewModel from "../../../../../shared/faq/application/model/faq-view-model";
import {GraphImagesEdge} from "../../../../../shared/infrastructure/graph-image-node";

export class ArticleTranslationFactory {
    private readonly response: Translation;
    private readonly opener_image_edge: GraphImagesEdge[];
    private readonly content_images_edges: GraphImagesEdge[];

    constructor(
        response: Translation,
        content_images_edges: GraphImagesEdge[],
        opener_image_edge: GraphImagesEdge[]
    ) {
        this.response = response;
        this.content_images_edges = content_images_edges;
        this.opener_image_edge = opener_image_edge;
    }

    public build(): ArticleTranslationViewModel {
        const props: ArticleTranslationViewModelProps = {
            authors: this.map_authors(),
            content: this.map_content(),
            date: this.map_dates(),
            faq: this.map_faq(),
            images: this.map_images(),
            language: this.map_language(),
            locator: this.map_locator(),
            meta: this.map_meta()

        }
        return ArticleTranslationViewModel.create(props);
    }

    private map_authors(): ArticleAuthorsType[] {
        const authors = this.response.authors;
        if (authors) {
            return authors.map((author) => {
                return {email: author.email, name: author.name}
            })
        } else {
            return []
        }
    }

    private map_content(): ArticleContentType {
        return new ArticleContentFactory(this.response).build()
    }

    private map_dates(): ArticleDates {
        return new ArticleDatesFactory(this.response).build()
    }

    private map_faq(): FaqViewModel[] {
        return new ArticleFaqFactory(this.response).build()
    }

    private map_images(): ArticleImagesType {
        return {
            raw_data: {
                content: this.content_images_edges,
                opener: this.opener_image_edge
            }
        }
    }

    private map_language(): { locale: string } {
        return {
            locale: this.response.languages_code.locale
        }
    }

    private map_locator(): { slug: string } {
        return {
            slug: this.response.slug
        }
    }

    private map_meta(): { description: string, title: string } {
        return {
            description: this.response.meta_description,
            title: this.response.meta_title
        }
    }

}