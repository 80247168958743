import {ViewModel} from "../../../building_blocks/view_model";

export interface FaqViewModelProps {
	question: string
	answer: string
}

export default class FaqViewModel extends ViewModel<FaqViewModelProps> {

	private constructor(props: FaqViewModelProps) {
		super(props);
	}

	public static create(props: FaqViewModelProps): FaqViewModel {
		return new FaqViewModel({...props})
	}

}
