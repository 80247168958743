import {ViewModel} from "../../../../../shared/building_blocks/view_model";
import {ArticleTranslationViewModel} from "./article-translation-view-model";
import SourceViewModelNew from "../../../../../shared/source/application/model/source-view-model-new";

export type ArticleAlternate = {
    locale: string
    slug: string
}

export type ArticleLocators = {
    umbrella_slug: string
    article_slug: string
}

export interface ArticleViewModelProps {
    locators: ArticleLocators
    available_alternates: ArticleAlternate[]
    translation: ArticleTranslationViewModel[]
    sources: SourceViewModelNew[]
}

export class ArticleViewModel extends ViewModel<ArticleViewModelProps> {

    private constructor(props: ArticleViewModelProps) {
        super(props);
    }

    public static create(props: ArticleViewModelProps): ArticleViewModel {
        return new ArticleViewModel({...props})
    }

    /**
     * Localized (filtered) by graphQL query
     */
    get translation(): ArticleTranslationViewModel {
        return this.props.translation[0];
    }

}
